var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row topmainrow" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c("div", { staticClass: "topmainlayer card" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$language("(PSM Based)")) + " "),
                _c("span", { staticClass: "title1" }, [
                  _vm._v(_vm._s(_vm.$language("안전보건관리체계 솔루션"))),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "title2" }, [
                  _vm._v(_vm._s(_vm.$language("Andami")) + " –"),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "title3" }, [
                  _vm._v(_vm._s(_vm.$language("SHMS"))),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "mainbtns" },
                [
                  _c(
                    "q-btn",
                    {
                      attrs: {
                        flat: "",
                        round: "",
                        color: "orange-custom",
                        icon: "science",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.goPage("/psi/hsl/hslManage")
                        },
                      },
                    },
                    [
                      _c("q-tooltip", [
                        _vm._v(_vm._s(_vm.$language("유해화학물질목록"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-btn",
                    {
                      attrs: {
                        flat: "",
                        round: "",
                        color: "orange-custom",
                        icon: "handyman",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.goPage("/sop/swp/safeWorkPermit")
                        },
                      },
                    },
                    [
                      _c("q-tooltip", [
                        _vm._v(_vm._s(_vm.$language("작업허가서"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-btn",
                    {
                      attrs: {
                        flat: "",
                        round: "",
                        color: "orange-custom",
                        icon: "school",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.goPage("/sop/edu/result/educationResult")
                        },
                      },
                    },
                    [
                      _c("q-tooltip", [
                        _vm._v(_vm._s(_vm.$language("교육계획 및 결과"))),
                      ]),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "q-btn",
                    {
                      attrs: {
                        flat: "",
                        round: "",
                        color: "orange-custom",
                        icon: "assessment",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.goPage("/sop/ibm/impr")
                        },
                      },
                    },
                    [
                      _c("q-tooltip", [
                        _vm._v(_vm._s(_vm.$language("개선관리"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-btn",
                    {
                      attrs: {
                        flat: "",
                        round: "",
                        color: "orange-custom",
                        icon: "pending_actions",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.goPage(
                            "/sop/mim/inspection/equipmentInspection"
                          )
                        },
                      },
                    },
                    [
                      _c("q-tooltip", [
                        _vm._v(_vm._s(_vm.$language("설비점검"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-btn",
                    {
                      attrs: {
                        flat: "",
                        round: "",
                        color: "orange-custom",
                        icon: "more_time",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.goPage(
                            "/sop/mim/maintenance/equipmentMaintenance"
                          )
                        },
                      },
                    },
                    [
                      _c("q-tooltip", [
                        _vm._v(_vm._s(_vm.$language("설비정비"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "today" }, [
                _vm._v("what to do Today..."),
              ]),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "row mainchild" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
          [
            _c("div", { staticClass: "maincard card" }, [
              _c("div", { staticClass: "card-body border-color-green" }, [
                _c("div", { staticClass: "d-flex align-items-center" }, [
                  _c("div", { staticClass: "flex-grow-1" }, [
                    _c(
                      "div",
                      { staticClass: "text-xs fw-bold text-positive mb-1" },
                      [_vm._v(_vm._s(_vm.$language("안전작업허가")))]
                    ),
                    _c("div", { staticClass: "h5" }, [_vm._v("5 건")]),
                    _c("div", { staticClass: "small fw-bold text-grey-5" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$language("안전작업허가 절차 준수")) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "ms-2" }, [
                    _c(
                      "span",
                      {
                        staticClass: "material-icons",
                        on: {
                          click: function ($event) {
                            return _vm.goPage("/sop/swp/safeWorkPermit")
                          },
                        },
                      },
                      [_vm._v("handyman")]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
          [
            _c("div", { staticClass: "maincard card" }, [
              _c("div", { staticClass: "card-body border-color-blue" }, [
                _c("div", { staticClass: "d-flex align-items-center" }, [
                  _c("div", { staticClass: "flex-grow-1" }, [
                    _c(
                      "div",
                      { staticClass: "text-xs fw-bold text-primary mb-1" },
                      [_vm._v(_vm._s(_vm.$language("근로자 등 교육")))]
                    ),
                    _c("div", { staticClass: "h5" }, [_vm._v("7 건")]),
                    _c("div", { staticClass: "small fw-bold text-grey-5" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$language(
                              "근로자(임직원)에 대해 실질적인 PSM교육"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "ms-2" }, [
                    _c(
                      "span",
                      {
                        staticClass: "material-icons",
                        on: {
                          click: function ($event) {
                            return _vm.goPage("/sop/edu/result/educationResult")
                          },
                        },
                      },
                      [_vm._v("school")]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
          [
            _c("div", { staticClass: "maincard card" }, [
              _c("div", { staticClass: "card-body border-color-red" }, [
                _c("div", { staticClass: "d-flex align-items-center" }, [
                  _c("div", { staticClass: "flex-grow-1" }, [
                    _c(
                      "div",
                      { staticClass: "text-xs fw-bold text-negative mb-1" },
                      [
                        _vm._v(
                          _vm._s(_vm.$language("설비점검/검사 및 유지/보수"))
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "h5" }, [_vm._v("4 건")]),
                    _c("div", { staticClass: "small fw-bold text-grey-5" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$language("설비별 위험등급에 따른 점검 및 검사")
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "ms-2" }, [
                    _c(
                      "span",
                      {
                        staticClass: "material-icons",
                        on: {
                          click: function ($event) {
                            return _vm.goPage(
                              "/sop/mim/inspection/equipmentInspection"
                            )
                          },
                        },
                      },
                      [_vm._v("engineering")]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
          [
            _c("div", { staticClass: "maincard card" }, [
              _c("div", { staticClass: "card-body border-color-yullow" }, [
                _c("div", { staticClass: "d-flex align-items-center" }, [
                  _c("div", { staticClass: "flex-grow-1" }, [
                    _c(
                      "div",
                      { staticClass: "text-xs fw-bold text-warning mb-1" },
                      [_vm._v(_vm._s(_vm.$language("변경요소 관리")))]
                    ),
                    _c("div", { staticClass: "h5" }, [_vm._v("9 건")]),
                    _c("div", { staticClass: "small fw-bold text-grey-5" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$language("설비 등 변경시 변경관리절차 준수")
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "ms-2" }, [
                    _c(
                      "span",
                      {
                        staticClass: "material-icons",
                        on: {
                          click: function ($event) {
                            return _vm.goPage("/sop/moc/mocNormal")
                          },
                        },
                      },
                      [_vm._v("published_with_changes")]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                },
                [
                  _c("div", { staticClass: "card cardcontents" }, [
                    _c("div", { staticClass: "card-header" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$language("공정별 위험성평가 현황")) +
                          " "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "card-body" },
                      [
                        _c("c-table", {
                          ref: "table1",
                          attrs: {
                            tableId: "table1",
                            isTitle: false,
                            isDashboard: true,
                            columnSetting: false,
                            usePaging: false,
                            filtering: false,
                            isFullScreen: false,
                            hideBottom: true,
                            gridHeight: "251px",
                            columns: _vm.grid1.columns,
                            data: _vm.grid1.data,
                          },
                          on: { rowClick: _vm.rowClick1 },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                },
                [
                  _c("div", { staticClass: "card cardcontents" }, [
                    _c("div", { staticClass: "card-header" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$language("설비 점검 및 정비 현황")) +
                          " "
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "card-more",
                          on: {
                            click: function ($event) {
                              return _vm.goPage(
                                "/sop/mim/inspection/equipmentInspection"
                              )
                            },
                          },
                        },
                        [_vm._v("more")]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "card-body" },
                      [
                        _c("c-table", {
                          ref: "table2",
                          attrs: {
                            tableId: "table2",
                            isTitle: false,
                            isDashboard: true,
                            columnSetting: false,
                            usePaging: false,
                            filtering: false,
                            isFullScreen: false,
                            hideBottom: true,
                            gridHeight: "251px",
                            columns: _vm.grid2.columns,
                            data: _vm.grid2.data,
                          },
                          on: { rowClick: _vm.rowClick2 },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
          [
            _c("div", { staticClass: "card cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" " + _vm._s(_vm.$language("작업허가서 MAP")) + " "),
                _c(
                  "div",
                  {
                    staticClass: "card-more",
                    on: {
                      click: function ($event) {
                        return _vm.goPage("/sop/swp/safeWorkPermit")
                      },
                    },
                  },
                  [_vm._v("more")]
                ),
                _c(
                  "div",
                  { staticClass: "card-more main-header-input" },
                  [
                    _c("c-datepicker", {
                      attrs: { name: "workPermitDate", default: "today" },
                      on: { datachange: _vm.getWorkPermit },
                      model: {
                        value: _vm.workPermit.date,
                        callback: function ($$v) {
                          _vm.$set(_vm.workPermit, "date", $$v)
                        },
                        expression: "workPermit.date",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _vm.workPermit.maps && _vm.workPermit.maps.length > 0
                    ? _c(
                        "q-carousel",
                        {
                          staticClass: "workPermit-carousel",
                          attrs: {
                            swipeable: "",
                            animated: "",
                            arrows: "",
                            "control-type": "regular",
                            "control-color": "orange",
                            infinite: "",
                          },
                          model: {
                            value: _vm.workPermit.slide,
                            callback: function ($$v) {
                              _vm.$set(_vm.workPermit, "slide", $$v)
                            },
                            expression: "workPermit.slide",
                          },
                        },
                        _vm._l(_vm.workPermit.maps, function (map, idx) {
                          return _c(
                            "q-carousel-slide",
                            {
                              key: idx,
                              attrs: { name: idx, "img-src": map.mapSrc },
                            },
                            [
                              _vm._l(map.maps, function (item, idx) {
                                return _c(
                                  "VueDraggableResizable",
                                  {
                                    key: idx,
                                    ref: "markImage",
                                    refInFor: true,
                                    staticClass: "mainMarkImage",
                                    attrs: {
                                      resizable: false,
                                      parent: true,
                                      draggable: false,
                                      x: item.locationXcoordinate,
                                      y: item.locationYcoordinate,
                                      w: 30,
                                      h: 20,
                                      grid: [30, 20],
                                    },
                                  },
                                  [
                                    _c("q-icon", {
                                      staticClass: "blinking",
                                      attrs: { name: "push_pin" },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "markImage-abbreviation-parent",
                                        style: {
                                          width: !item.isHover
                                            ? item.resultTypes.length > 3
                                              ? "227px"
                                              : _vm.getWidth(item)
                                            : "241px",
                                          left: _vm.getLeft(item),
                                          top: _vm.getTop(item),
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "markImage-abbreviation",
                                            on: {
                                              mouseover: () => {
                                                item.isHover = true
                                              },
                                              mouseleave: () => {
                                                item.isHover = false
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "transition",
                                              {
                                                attrs: {
                                                  name: "mark-list",
                                                  tag: "div",
                                                },
                                              },
                                              [
                                                !item.isHover
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "markImage-tran",
                                                      },
                                                      [
                                                        _vm._l(
                                                          item.resultTypes.slice(
                                                            0,
                                                            3
                                                          ),
                                                          function (
                                                            resultType,
                                                            idx
                                                          ) {
                                                            return _c(
                                                              "q-banner",
                                                              {
                                                                key: idx,
                                                                class: [
                                                                  "markImage-abbreviation-banner",
                                                                  item
                                                                    .resultTypes
                                                                    .length <
                                                                    4 &&
                                                                  idx ===
                                                                    item
                                                                      .resultTypes
                                                                      .length -
                                                                      1
                                                                    ? "last-banner"
                                                                    : "",
                                                                ],
                                                                attrs: {
                                                                  dense: "",
                                                                },
                                                              },
                                                              [
                                                                _c("q-icon", {
                                                                  staticClass:
                                                                    "text-red",
                                                                  attrs: {
                                                                    name: _vm.workPermitIconName(
                                                                      resultType
                                                                    ),
                                                                  },
                                                                }),
                                                                _c("div", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        resultType.sopWorkTypeName
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        item.resultTypes
                                                          .length > 3
                                                          ? _c(
                                                              "q-banner",
                                                              {
                                                                key: "-1",
                                                                class: [
                                                                  "markImage-abbreviation-plus",
                                                                ],
                                                                attrs: {
                                                                  dense: "",
                                                                },
                                                              },
                                                              [
                                                                _c("q-icon", {
                                                                  staticClass:
                                                                    "text-red",
                                                                  attrs: {
                                                                    name: "add",
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item
                                                                      .resultTypes
                                                                      .length -
                                                                      3
                                                                  ) + " "
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "q-icon",
                                                                      {
                                                                        staticClass:
                                                                          "text-black",
                                                                        attrs: {
                                                                          name: "more_horiz",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "transition",
                                              {
                                                attrs: {
                                                  name: "mark-list",
                                                  tag: "div",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: item.isHover,
                                                        expression:
                                                          "item.isHover",
                                                      },
                                                    ],
                                                  },
                                                  _vm._l(
                                                    item.resultTypes,
                                                    function (resultType, idx) {
                                                      return _c(
                                                        "q-banner",
                                                        {
                                                          key: idx,
                                                          class: [
                                                            idx % 2 === 1
                                                              ? "bg-grey-3"
                                                              : "bg-grey-1",
                                                            "markImage-abbreviation-banner-detail",
                                                          ],
                                                          attrs: { dense: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "avatar",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "q-icon",
                                                                      {
                                                                        staticClass:
                                                                          "text-red",
                                                                        attrs: {
                                                                          name: _vm.workPermitIconName(
                                                                            resultType
                                                                          ),
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                resultType.sopWorkTypeName
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("q-badge", {
                                                            attrs: {
                                                              color:
                                                                _vm.workPermitbadgeColor(
                                                                  resultType.swpStepName
                                                                ),
                                                              label:
                                                                resultType.swpStepName,
                                                            },
                                                          }),
                                                          _c("q-btn", {
                                                            staticClass:
                                                              "q-ml-sm",
                                                            attrs: {
                                                              outline: "",
                                                              round: "",
                                                              dense: "",
                                                              color: "red",
                                                              size: "9px",
                                                              label: "",
                                                              icon: "add",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openWorkPermit(
                                                                  resultType
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "caption-work",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    resultType.workSummary
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          )
                        }),
                        1
                      )
                    : _c("el-empty", {
                        attrs: {
                          "image-size": 435,
                          description: _vm.$language(
                            "지도에 등록된 작업허가서가 없습니다."
                          ),
                        },
                      }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
          [
            _c("div", { staticClass: "card cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" " + _vm._s(_vm.$language("공지사항")) + " "),
                _c(
                  "div",
                  {
                    staticClass: "card-more",
                    on: {
                      click: function ($event) {
                        return _vm.goPage("/sys/notice/noticeManage")
                      },
                    },
                  },
                  [_vm._v("more")]
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("c-table", {
                    ref: "table6",
                    attrs: {
                      tableId: "table6",
                      isTitle: false,
                      isDashboard: true,
                      columnSetting: false,
                      usePaging: false,
                      filtering: false,
                      isFullScreen: false,
                      hideBottom: true,
                      gridHeight: "209px",
                      columns: _vm.grid6.columns,
                      data: _vm.grid6.data,
                    },
                    on: { rowClick: _vm.rowClick6 },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
          [
            _c("div", { staticClass: "card cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" " + _vm._s(_vm.$language("교육진행 현황")) + " "),
                _c(
                  "div",
                  {
                    staticClass: "card-more",
                    on: {
                      click: function ($event) {
                        return _vm.goPage("/sop/edu/result/educationResult")
                      },
                    },
                  },
                  [_vm._v("more")]
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("c-table", {
                    ref: "table3",
                    attrs: {
                      tableId: "table3",
                      isTitle: false,
                      isDashboard: true,
                      columnSetting: false,
                      usePaging: false,
                      filtering: false,
                      isFullScreen: false,
                      hideBottom: true,
                      gridHeight: "209px",
                      columns: _vm.grid3.columns,
                      data: _vm.grid3.data,
                    },
                    on: { rowClick: _vm.rowClick3 },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
          [
            _c("div", { staticClass: "card cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(
                  " " + _vm._s(_vm.$language("인허가 요청 및 검토 현황")) + " "
                ),
                _c(
                  "div",
                  {
                    staticClass: "card-more",
                    on: {
                      click: function ($event) {
                        return _vm.goPage("/sop/lim/licenseRequest")
                      },
                    },
                  },
                  [_vm._v("more")]
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("c-table", {
                    ref: "table4",
                    attrs: {
                      tableId: "table4",
                      isTitle: false,
                      isDashboard: true,
                      columnSetting: false,
                      usePaging: false,
                      filtering: false,
                      isFullScreen: false,
                      hideBottom: true,
                      gridHeight: "209px",
                      columns: _vm.grid4.columns,
                      data: _vm.grid4.data,
                    },
                    on: { rowClick: _vm.rowClick3 },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c("div", { staticClass: "card cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(
                  " " + _vm._s(_vm.$language("통합개선 이행율 현황")) + " "
                ),
                _c(
                  "div",
                  {
                    staticClass: "card-more",
                    on: {
                      click: function ($event) {
                        return _vm.goPage("/sop/ibm/imprActionRate")
                      },
                    },
                  },
                  [_vm._v("more")]
                ),
                _c(
                  "div",
                  { staticClass: "card-more main-header-input" },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        range: true,
                        defaultStart: "-2M",
                        defaultEnd: "2M",
                        type: "month",
                        name: "actionCompleteRequestDates",
                      },
                      on: { datachange: _vm.getImprStatus },
                      model: {
                        value: _vm.grid5.actionCompleteRequestDates,
                        callback: function ($$v) {
                          _vm.$set(_vm.grid5, "actionCompleteRequestDates", $$v)
                        },
                        expression: "grid5.actionCompleteRequestDates",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("c-table", {
                    ref: "table5",
                    attrs: {
                      title: "개선 이행률 - 업무별",
                      tableId: "table5",
                      isTitle: false,
                      isDashboard: true,
                      columnSetting: false,
                      usePaging: false,
                      filtering: false,
                      isFullScreen: false,
                      hideBottom: true,
                      columns: _vm.grid5.columns,
                      data: _vm.grid5.data,
                      gridHeight: "350px",
                    },
                    on: { linkClick: _vm.linkImprClick },
                  }),
                  _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c("div", { staticClass: "card cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" " + _vm._s(_vm.$language("변경관리(MOC현황)")) + " "),
                _c(
                  "div",
                  {
                    staticClass: "card-more",
                    on: {
                      click: function ($event) {
                        return _vm.goPage("/dashboard/moc/mocDashboard")
                      },
                    },
                  },
                  [_vm._v("more")]
                ),
              ]),
              _c("div", { staticClass: "card-body" }),
            ]),
          ]
        ),
      ]),
      _c(
        "q-dialog",
        {
          attrs: { position: "top" },
          model: {
            value: _vm.noticedialog,
            callback: function ($$v) {
              _vm.noticedialog = $$v
            },
            expression: "noticedialog",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { "min-width": "800px", width: "800px" } },
            [
              _c(
                "q-card-section",
                { staticClass: "row items-center no-wrap mainpoplayer" },
                [
                  _c(
                    "q-list",
                    {
                      staticClass: "rounded-borders",
                      staticStyle: { width: "100%" },
                      attrs: { bordered: "" },
                    },
                    _vm._l(_vm.popNotices, function (tag) {
                      return _c(
                        "q-expansion-item",
                        {
                          key: tag.sysNoticeId,
                          staticStyle: { "border-bottom": "1px solid #fff" },
                          attrs: {
                            group: "noticepops",
                            "header-class": "bg-orange-custom text-white",
                            "expand-icon-class": "text-white",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c(
                                      "q-item-section",
                                      { attrs: { avatar: "" } },
                                      [
                                        _c("q-icon", {
                                          attrs: {
                                            color: "white",
                                            name: "mail",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("q-item-section", [
                                      _vm._v(
                                        " " + _vm._s(tag.noticeTitle) + " "
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: tag.expanded,
                            callback: function ($$v) {
                              _vm.$set(tag, "expanded", $$v)
                            },
                            expression: "tag.expanded",
                          },
                        },
                        [
                          _c(
                            "q-card",
                            [
                              _c("q-card-section", [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(tag.noticeContents),
                                  },
                                }),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "q-card",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: tag.dayViewDisable,
                                  expression: "tag.dayViewDisable",
                                },
                              ],
                            },
                            [
                              _c(
                                "q-card-section",
                                [
                                  _c("q-toggle", {
                                    staticClass: "anymoreNotice",
                                    attrs: {
                                      size: "sm",
                                      "checked-icon": "check",
                                      color: "red",
                                      label: _vm.$language("더이상 보지 않기"),
                                      "unchecked-icon": "clear",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.updateTodayChk(
                                          tag.sysNoticeId
                                        )
                                      },
                                    },
                                    model: {
                                      value: tag.dayView,
                                      callback: function ($$v) {
                                        _vm.$set(tag, "dayView", $$v)
                                      },
                                      expression: "tag.dayView",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }