<template>
  <div>
    <div class="row topmainrow">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="topmainlayer card">
          <div class="title">{{$language('(PSM Based)')}} <span class="title1">{{$language('안전보건관리체계 솔루션')}}</span> <span class="title2">{{$language('Andami')}} –</span> <span class="title3">{{$language('SHMS')}}</span></div>
          <div class="mainbtns">
            <q-btn flat round color="orange-custom" icon="science" @click="goPage('/psi/hsl/hslManage')"><q-tooltip>{{$language('유해화학물질목록')}}</q-tooltip></q-btn>
            <q-btn flat round color="orange-custom" icon="handyman" @click="goPage('/sop/swp/safeWorkPermit')"><q-tooltip>{{$language('작업허가서')}}</q-tooltip></q-btn>
            <q-btn flat round color="orange-custom" icon="school" @click="goPage('/sop/edu/result/educationResult')"><q-tooltip>{{$language('교육계획 및 결과')}}</q-tooltip></q-btn><br>
            <q-btn flat round color="orange-custom" icon="assessment" @click="goPage('/sop/ibm/impr')"><q-tooltip>{{$language('개선관리')}}</q-tooltip></q-btn>
            <q-btn flat round color="orange-custom" icon="pending_actions" @click="goPage('/sop/mim/inspection/equipmentInspection')"><q-tooltip>{{$language('설비점검')}}</q-tooltip></q-btn>
            <q-btn flat round color="orange-custom" icon="more_time" @click="goPage('/sop/mim/maintenance/equipmentMaintenance')"><q-tooltip>{{$language('설비정비')}}</q-tooltip></q-btn>
          </div>
          <div class="today">what to do Today...</div>
        </div>
      </div>
    </div>
    <div class="row mainchild">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <div class="maincard card">
          <div class="card-body border-color-green">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <div class="text-xs fw-bold text-positive mb-1">{{$language('안전작업허가')}}</div>
                <div class="h5">5 건</div>
                <div class="small fw-bold text-grey-5">
                  {{$language('안전작업허가 절차 준수')}}
                </div>
              </div>
              <div class="ms-2">
                <span class="material-icons" @click="goPage('/sop/swp/safeWorkPermit')">handyman</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <div class="maincard card">
          <div class="card-body border-color-blue">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <div class="text-xs fw-bold text-primary mb-1">{{$language('근로자 등 교육')}}</div>
                <div class="h5">7 건</div>
                <div class="small fw-bold text-grey-5">
                  {{$language('근로자(임직원)에 대해 실질적인 PSM교육')}}
                </div>
              </div>
              <div class="ms-2">
                <span class="material-icons" @click="goPage('/sop/edu/result/educationResult')">school</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <div class="maincard card">
          <div class="card-body border-color-red">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <div class="text-xs fw-bold text-negative mb-1">{{$language('설비점검/검사 및 유지/보수')}}</div>
                <div class="h5">4 건</div>
                <div class="small fw-bold text-grey-5">
                  {{$language('설비별 위험등급에 따른 점검 및 검사')}}
                </div>
              </div>
              <div class="ms-2">
                <span class="material-icons" @click="goPage('/sop/mim/inspection/equipmentInspection')">engineering</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <div class="maincard card">
          <div class="card-body border-color-yullow">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <div class="text-xs fw-bold text-warning mb-1">{{$language('변경요소 관리')}}</div>
                <div class="h5">9 건</div>
                <div class="small fw-bold text-grey-5">
                  {{$language('설비 등 변경시 변경관리절차 준수')}}
                </div>
              </div>
              <div class="ms-2">
                <span class="material-icons" @click="goPage('/sop/moc/mocNormal')">published_with_changes</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="card cardcontents">
              <div class="card-header">
                {{$language('공정별 위험성평가 현황')}}
              </div>
              <div class="card-body">
                <c-table
                  ref="table1"
                  tableId="table1"
                  :isTitle="false"
                  :isDashboard="true"
                  :columnSetting="false"
                  :usePaging="false"
                  :filtering="false"
                  :isFullScreen="false"
                  :hideBottom="true"
                  gridHeight="251px"
                  :columns="grid1.columns"
                  :data="grid1.data"
                  @rowClick="rowClick1"
                >
                </c-table>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="card cardcontents">
              <div class="card-header">
                {{$language('설비 점검 및 정비 현황')}}
                <div class="card-more" @click="goPage('/sop/mim/inspection/equipmentInspection')">more</div>
              </div>
              <div class="card-body">
                <c-table
                  ref="table2"
                  tableId="table2"
                  :isTitle="false"
                  :isDashboard="true"
                  :columnSetting="false"
                  :usePaging="false"
                  :filtering="false"
                  :isFullScreen="false"
                  :hideBottom="true"
                  gridHeight="251px"
                  :columns="grid2.columns"
                  :data="grid2.data"
                  @rowClick="rowClick2"
                >
                </c-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="card cardcontents">
          <div class="card-header">
            {{$language('작업허가서 MAP')}}
            <div class="card-more" @click="goPage('/sop/swp/safeWorkPermit')">more</div>
            <div class="card-more main-header-input">
              <c-datepicker
                name="workPermitDate"
                default="today"
                v-model="workPermit.date"
                @datachange="getWorkPermit"
              />
            </div>
          </div>
          <div class="card-body">
            <q-carousel
              v-if="workPermit.maps && workPermit.maps.length > 0"
              class="workPermit-carousel"
              swipeable
              animated
              arrows
              control-type="regular"
              control-color="orange"
              v-model="workPermit.slide"
              infinite
            >
              <q-carousel-slide 
                v-for="(map, idx) in workPermit.maps"
                :key="idx"
                :name="idx" 
                :img-src="map.mapSrc">
                <template>
                  <VueDraggableResizable
                    v-for="(item, idx) in map.maps"
                    :key="idx"
                    ref="markImage"
                    class="mainMarkImage"
                    :resizable="false"
                    :parent="true"
                    :draggable="false"
                    :x="item.locationXcoordinate"
                    :y="item.locationYcoordinate"
                    :w="30" :h="20"
                    :grid="[30, 20]"
                  >
                    <!-- {{idx + 1}} -->
                    <q-icon name="push_pin" class="blinking" />
                    <div class="markImage-abbreviation-parent"
                      :style="{ 
                        width: !item.isHover 
                          ? item.resultTypes.length > 3 ? '227px' : getWidth(item)
                          : '241px', 
                        left: getLeft(item),
                        top: getTop(item),
                      }">
                      <div class="markImage-abbreviation" 
                        @mouseover="() => { item.isHover = true }"
                        @mouseleave="() => { item.isHover = false }">
                        <!-- @mouseover="markImageDetail(item)" -->
                        <transition name="mark-list" tag="div">
                          <div v-if="!item.isHover" class="markImage-tran">
                            <q-banner v-for="(resultType, idx) in item.resultTypes.slice(0, 3)"
                              :key="idx"
                              dense 
                              :class="[
                                'markImage-abbreviation-banner',
                                item.resultTypes.length < 4 && idx === item.resultTypes.length - 1 ? 'last-banner' : ''
                              ]">
                              <!-- <template v-slot:avatar>
                                <q-icon :name="workPermitIconName(resultType)" class="text-red" />
                                <div>
                                  {{resultType.sopWorkTypeName}}
                                </div>
                              </template> -->
                              <q-icon :name="workPermitIconName(resultType)" class="text-red" />
                              <div>
                                {{resultType.sopWorkTypeName}}
                              </div>
                            </q-banner>
                            <q-banner v-if="item.resultTypes.length > 3"
                              key="-1"
                              dense 
                              :class="['markImage-abbreviation-plus']">
                              <q-icon name="add" class="text-red" />{{item.resultTypes.length - 3}}
                              <div>
                                <q-icon name="more_horiz" class="text-black" />
                              </div>
                            </q-banner>
                          </div>
                        </transition>
                        <transition name="mark-list" tag="div">
                          <div v-show="item.isHover">
                            <q-banner v-for="(resultType, idx) in item.resultTypes"
                              :key="idx"
                              dense 
                              :class="[idx % 2 ===1 ? 'bg-grey-3' : 'bg-grey-1', 'markImage-abbreviation-banner-detail']">
                              <template v-slot:avatar>
                                <q-icon :name="workPermitIconName(resultType)" class="text-red" />
                              </template>
                              {{resultType.sopWorkTypeName}}
                              <q-badge :color="workPermitbadgeColor(resultType.swpStepName)" :label="resultType.swpStepName" />
                              <q-btn class="q-ml-sm" outline round dense color="red" size="9px" label="" icon="add" @click="openWorkPermit(resultType)" />
                              <div class="caption-work">
                                {{resultType.workSummary}}
                              </div>
                              <!-- <template v-slot:action>
                                <q-btn flat color="red" size="md" label="더보기" icon="add" @click="openWorkPermit(resultType)" />
                              </template> -->
                            </q-banner>
                          </div>
                        </transition>
                      </div>
                    </div>
                    <!-- <q-popup-proxy transition-show="flip-up" transition-hide="flip-down" inline-actions
                      :value="true" >
                      <q-banner v-for="(resultType, idx) in item.resultTypes"
                        :key="idx"
                        dense 
                        :class="idx % 2 ===1 ? 'bg-grey-3' : 'bg-grey-1'">
                        <template v-slot:avatar>
                          <q-icon :name="workPermitIconName(resultType)" class="text-red" />
                        </template>
                        {{resultType.sopWorkTypeName}}
                        <q-badge :color="workPermitbadgeColor(resultType.swpStepName)" :label="resultType.swpStepName" />
                        <div class="caption-work">
                          {{resultType.workSummary}}
                        </div>
                        <template v-slot:action>
                          <q-btn flat color="red" size="md" label="더보기" icon="add" @click="openWorkPermit(resultType)" />
                        </template>
                      </q-banner>
                    </q-popup-proxy> -->
                  </VueDraggableResizable>
                </template>
              </q-carousel-slide>
            </q-carousel>
            <!-- 지도에 등록된 작업허가서가 없습니다. -->
            <el-empty v-else 
              :image-size="435"
              :description="$language('지도에 등록된 작업허가서가 없습니다.')"></el-empty>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <div class="card cardcontents">
          <div class="card-header">
            {{$language('공지사항')}}
            <div class="card-more" @click="goPage('/sys/notice/noticeManage')">more</div>
          </div>
          <div class="card-body">
            <c-table
              ref="table6"
              tableId="table6"
              :isTitle="false"
              :isDashboard="true"
              :columnSetting="false"
              :usePaging="false"
              :filtering="false"
              :isFullScreen="false"
              :hideBottom="true"
              gridHeight="209px"
              :columns="grid6.columns"
              :data="grid6.data"
              @rowClick="rowClick6"
            >
            </c-table>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <div class="card cardcontents">
          <div class="card-header">
            {{$language('교육진행 현황')}}
            <div class="card-more" @click="goPage('/sop/edu/result/educationResult')">more</div>
          </div>
          <div class="card-body">
            <c-table
              ref="table3"
              tableId="table3"
              :isTitle="false"
              :isDashboard="true"
              :columnSetting="false"
              :usePaging="false"
              :filtering="false"
              :isFullScreen="false"
              :hideBottom="true"
              gridHeight="209px"
              :columns="grid3.columns"
              :data="grid3.data"
              @rowClick="rowClick3"
            >
            </c-table>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <div class="card cardcontents">
          <div class="card-header">
            {{$language('인허가 요청 및 검토 현황')}}
            <div class="card-more" @click="goPage('/sop/lim/licenseRequest')">more</div>
          </div>
          <div class="card-body">
            <c-table
              ref="table4"
              tableId="table4"
              :isTitle="false"
              :isDashboard="true"
              :columnSetting="false"
              :usePaging="false"
              :filtering="false"
              :isFullScreen="false"
              :hideBottom="true"
              gridHeight="209px"
              :columns="grid4.columns"
              :data="grid4.data"
              @rowClick="rowClick3"
            >
            </c-table>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card cardcontents">
          <div class="card-header">
            {{$language('통합개선 이행율 현황')}}
            <div class="card-more" @click="goPage('/sop/ibm/imprActionRate')">more</div>
            <div class="card-more main-header-input">
              <c-datepicker
                :range="true"
                defaultStart="-2M"
                defaultEnd="2M"
                type="month"
                name="actionCompleteRequestDates"
                v-model="grid5.actionCompleteRequestDates"
                @datachange="getImprStatus"
              />
            </div>
          </div>
          <div class="card-body">
            <c-table
              ref="table5"
              title="개선 이행률 - 업무별"
              tableId="table5"
              :isTitle="false"
              :isDashboard="true"
              :columnSetting="false"
              :usePaging="false"
              :filtering="false"
              :isFullScreen="false"
              :hideBottom="true"
              :columns="grid5.columns"
              :data="grid5.data"
              gridHeight="350px"
              @linkClick="linkImprClick"
            >
            </c-table>
            <c-dialog :param="popupOptions"></c-dialog>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card cardcontents">
          <div class="card-header">
            {{$language('변경관리(MOC현황)')}}
            <div class="card-more" @click="goPage('/dashboard/moc/mocDashboard')">more</div>
          </div>
          <div class="card-body">
          </div>
        </div>
      </div>
    </div>
    <q-dialog v-model="noticedialog" position="top">
      <q-card style="min-width: 800px;width: 800px">
        <q-card-section class="row items-center no-wrap mainpoplayer">
          <q-list bordered class="rounded-borders" style="width: 100%">
            <q-expansion-item v-for="tag in popNotices"
              :key="tag.sysNoticeId"
              group="noticepops"
              header-class="bg-orange-custom text-white"
              style="border-bottom: 1px solid #fff;"
              expand-icon-class="text-white"
              v-model="tag.expanded">
              <template v-slot:header>
                <q-item-section avatar>
                  <q-icon color="white" name="mail" />
                </q-item-section>
                <q-item-section>
                  {{tag.noticeTitle}}
                </q-item-section>
              </template>
              <q-card>
                <q-card-section>
                  <div v-html="tag.noticeContents"></div>
                </q-card-section>
              </q-card>
              <q-card v-show="tag.dayViewDisable">
                <q-card-section>
                  <q-toggle
                    size="sm"
                    class="anymoreNotice"
                    v-model="tag.dayView"
                    checked-icon="check"
                    color="red"
                    :label="$language('더이상 보지 않기')"
                    unchecked-icon="clear"
                    @input="updateTodayChk(tag.sysNoticeId)"
                  />
                </q-card-section>
              </q-card>
            </q-expansion-item>
          </q-list>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import selectConfig from '@/js/selectConfig';

export default {
  name: 'main-dashboard',

  components: { VueDraggableResizable },
  props: {
  },
  data() {
    return {
      noticedialog: false,
      workPermit: {
        slide: 0,
        date: '',
        listUrl: '',
        mapSrc: require('@/assets/images/no-image.png'),
        maps: [],
      },
      isHover: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      noticePopUrl: '',
      popNotices: [],
      grid1: {
        listUrl: '',
        columns: [
          {
            name: 'ramTechniqueName',
            field: 'ramTechniqueName',
            label: '평가기법',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'assessmentName',
            field: 'assessmentName',
            label: '평가명',
            align: 'left',
            sortable: false,
            style: 'width:180px',
          },
          {
            name: 'assessmentDate',
            field: 'assessmentDate',
            label: '평가기간',
            align: 'center',
            sortable: false,
            style: 'width:120px',
          },
          {
            name: 'riskRegisterCnt',
            field: 'riskRegisterCnt',
            label: '위험등록부 등재 건 수',
            align: 'center',
            sortable: false,
            style: 'width:50px',
            type: 'badge',
            color: 'negative'
          },
          {
            name: 'imprCnt',
            field: 'imprCnt',
            label: '개선 건 수',
            align: 'center',
            sortable: false,
            style: 'width:50px',
            type: 'badge',
            color: 'light-green'
          },
        ],
        data: [],
      },
      grid2: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '설비명(관리번호)',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '분류',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '점검/정비 유형',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '점검/정비 일',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      grid3: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '교육명',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '교육과정',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '교육일시',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      grid4: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '인허가 요청명',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '요청부서',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '진행단계',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '검토부서',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      grid5: {
        listUrl: '',
        actionCompleteRequestDates: [],
        columns: [
          {
            // 업무
            name: 'ibmTaskTypeName',
            field: 'ibmTaskTypeName',
            label: '업무',
            align: 'center',
            style: 'width:120px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'requesting',
            field: 'requesting',
            label: '요청중',
            align: 'right',
            style: 'width:80px',
            sortable: false,
            type: 'cost',
          },
          {
            name: 'receipting',
            field: 'receipting',
            label: '조치부서 접수중',
            align: 'right',
            style: 'width:80px',
            sortable: true,
            type: 'cost',
          },
          {
            name: 'actioning',
            field: 'actioning',
            label: '조치부서 조치중',
            align: 'right',
            style: 'width:80px',
            sortable: true,
            type: 'cost',
          },
          {
            name: 'confirming',
            field: 'confirming',
            label: '요청부서 검토중',
            align: 'right',
            style: 'width:80px',
            sortable: true,
            type: 'cost',
          },
          {
            name: 'complete',
            field: 'complete',
            label: '개선완료',
            align: 'right',
            style: 'width:80px',
            sortable: true,
            type: 'cost',
          },
          {
            name: 'overAction',
            field: 'overAction',
            label: '지연 건수',
            align: 'right',
            style: 'width:80px',
            sortable: true,
            type: 'cost',
          },
          {
            name: 'immTotal',
            field: 'immTotal',
            label: '즉시처리건',
            align: 'center',
            style: 'width:100px',
            sortable: true,
            type: 'cost',
          },
        ],
        data: [],
      },
      grid6: {
        listUrl: '',
        columns: [
          {
            name: 'noticeClassName',
            field: 'noticeClassName',
            label: '공지분류',
            align: 'center',
            style: 'width: 100px',
            sortable: true,
          },
          {
            name: 'noticeTitle',
            field: 'noticeTitle',
            label: '제목',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // url setting
      this.workPermit.listUrl = selectConfig.main.workPermit.url
      this.grid1.listUrl = selectConfig.main.riskAssess.url
      this.grid5.listUrl = selectConfig.sop.ibm.improve.status.task.url
      this.grid6.listUrl = selectConfig.sys.notice.list.url;
      this.noticePopUrl = selectConfig.sys.notice.main.url;
      // list setting
      this.getWorkPermit();
      this.getRiskAssess();
      this.getImprStatus();
      this.getNotice();
      // this.getNoticePop();
      this.grid2.data = [
        {
          col1: '# 1호 OIL SE********(EC00000001)',
          col2: '점검',
          col3: '자체(일상)',
          col4: '2021-11-03 ~ 2021-11-19',
        },
        {
          col1: '# 1호 OIL SE********(EC00000001)',
          col2: '점검',
          col3: '자체(일상)',
          col4: '2021-10-01 ~ 2021-12-31',
        },
        {
          col1: '# 1호 OIL SE********(EC00000001)',
          col2: '정비',
          col3: '고장정비',
          col4: '2021-10-01 ~ 2021-12-31',
        }
      ];
      this.grid3.data = [
        {
          col1: '2021 1사업장 수급업체 교육',
          col2: '수급업체 안전교육',
          col3: '2021-11-03 ~ 2021-11-19',
        },
        {
          col1: '비상사태 대응 훈련 ',
          col2: '비상사태 대응 훈련 ',
          col3: '2021-10-01 ~ 2021-12-31',
        }
      ];
      this.grid4.data = [
        {
          col1: '설비증설관련 인허가 요청',
          col2: '생산팀 A/ 관리자',
          col3: '검토 및 진행중',
          col4: '생산팀 A/ 김길동',
        },
      ];
    },
    getWorkPermit() {
      let defaultPlantCd = window.sessionStorage.getItem('defaultPlantCd');
      this.$http.url = this.workPermit.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: defaultPlantCd,
        date: this.workPermit.date
      }
      this.$http.request((_result) => {
        this.workPermit.maps = _result.data;
        this.$_.forEach(this.workPermit.maps, map => {
          this.$_.forEach(map.maps, item => {
            this.$set(item, 'isHover', false)
          });
        })
      },);
    },
    workPermitIconName(resultType) {
      let icon = '';
      switch(resultType.sopWorkTypeCd) {
        case 'SPT0000001': // 일반
          icon = 'engineering';
          break;
        case 'SPT0000005': // 화기
          icon = 'local_fire_department';
          break;
        case 'SSWT000001': // 밀폐공간
          icon = 'dangerous';
          break;
        case 'SSWT000005': // 정전
          icon = 'flash_off';
          break;
        case 'SSWT000010': // 방사선
          icon = 'warning';
          break;
        case 'SSWT000015': // 굴착
          icon = 'hardware';
          break;
        case 'SSWT000020': // 고소
          icon = 'stairs';
          break;
        case 'SSWT000025': // 중장비
          icon = 'agriculture';
          break;
      }
      return icon;
    },
    workPermitbadgeColor(swpStepName) {
      let icon = '';
      switch(swpStepName) {
        case '승인완료':
          icon = 'light-blue';
          break;
        case '연장':
          icon = 'amber';
          break;
        case '작업완료':
          icon = 'light-green';
          break;
      }
      return icon;
    },
    openWorkPermit(item) {
      if (!item) return;
      this.popupOptions.isFull = true;
      this.popupOptions.title = '작업허가서'; 
      this.popupOptions.param = {
        sopWorkPermitId: item.sopWorkPermitId,
        permitTypeCd: item.permitTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    markImageDetail() {

    },
    getRiskAssess() {
      this.$http.url = this.grid1.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.$store.getters.user.plantCd
      }
      this.$http.request((_result) => {
        this.grid1.data = _result.data;
      },);
    },
    getImprStatus() {
      this.$http.url = this.grid5.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.$store.getters.user.plantCd,
        actionCompleteRequestDates: this.grid5.actionCompleteRequestDates,
        actionDeptCd: this.$store.getters.user.deptCd,
      }
      this.$http.request((_result) => {
        this.grid5.data = _result.data;
      },);
    },
    getNotice() {
      this.$http.url = this.grid6.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        vendorFlag: 'N',
        pagelimit: 5,
      }
      this.$http.request((_result) => {
        this.grid6.data = _result.data;
      },);
    },
    getNoticePop() {
      this.popNotices = [];
      this.$http.url = this.noticePopUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        vendorFlag: 'N',
      }
      this.$http.request((_result) => {
        let mainPopNoticeId = window.localStorage.getItem('mainPopNoticeId');
        if (mainPopNoticeId !== null) {
          let icnt=0;
          this.$_.forEach(_result.data, item => {
            if (mainPopNoticeId.indexOf(item.sysNoticeId) < 0) {
              item.dayViewDisable = true;
              if (icnt == 0) {
                item.expanded = true;
              } else {
                item.expanded = false;
              }
              this.popNotices.push(item);
              icnt++;
            }
          })
          if (this.popNotices.length == _result.data.length) {
            window.localStorage.removeItem('mainPopNoticeId');
          }
        } else {
          this.popNotices = _result.data;
          let icnt=0;
          this.$_.forEach(this.popNotices, item => {
            item.dayViewDisable = true;
            if (icnt == 0) {
              item.expanded = true;
            } else {
              item.expanded = false;
            }
            icnt++;
          })
        }
        if (this.popNotices.length > 0) {
          this.noticedialog = true
        }
      },);
    },
    getNoticeDetail(_title, _content) {
      this.popNotices = [{
        sysNoticeId: '',
        expanded: true,
        dayView: false,
        noticeTitle: _title,
        noticeContents: _content,
        dayViewDisable: false,
      }];
      this.noticedialog = true;
    },
    updateTodayChk(_noticeId) {
      let mainPopNoticeId = window.localStorage.getItem('mainPopNoticeId');
      if (mainPopNoticeId === null) {
        window.localStorage.setItem('mainPopNoticeId', _noticeId + ',');
      } else {
        if (mainPopNoticeId.indexOf(_noticeId) < 0) {
          window.localStorage.setItem('mainPopNoticeId', mainPopNoticeId + _noticeId + ',');
        } else {
          window.localStorage.setItem('mainPopNoticeId', mainPopNoticeId.replace(_noticeId + ',',''));
        }
      }

    },
    linkImprClick(row) {
      this.popupOptions.title = this.$language('업무별 개선 이행률 상세') + '('+ row.ibmTaskTypeName + ')'
      this.popupOptions.param = {
        tab: 'task',
        clickInfo: row.ibmTaskTypeName,
        data: row,
        searchParam: {
          plantCd: this.$store.getters.user.plantCd,
          actionCompleteRequestDates: this.grid5.actionCompleteRequestDates,
          actionDeptCd: this.$store.getters.user.deptCd,
          ibmTaskTypeCd: row.ibmTaskTypeCd,
        }
      };
      this.popupOptions.isFull = false;
      this.popupOptions.width = '80%';
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprActionRateDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    goPage(_page) {
      this.$comm.movePage(this.$router, _page)
    },
    /* eslint-disable no-unused-vars */
    rowClick1(row) {
    },
    rowClick2(row) {
    },
    rowClick3(row) {
    },
    rowClick6(row) {
      this.getNoticeDetail(row.noticeTitle, row.noticeContents);
    },
    getWidth(item) {
      let width = 0;
      this.$_.forEach(item.resultTypes, resultType => {
        width += (resultType.sopWorkTypeName.length * 10) + 60
      })
      return width + 'px'
    },
    getLeft(item) {
      /**
       * 지도의 크기는 800
       */
      let left = 0;
      let nonDetailLocation = item.locationXcoordinate + (item.resultTypes.length * 40)
      let detailLocation = item.locationXcoordinate + 250
      let calLocation = nonDetailLocation > detailLocation ? nonDetailLocation : detailLocation
      if (calLocation > 800) {
        left = 800 - calLocation
      }
      return left + 'px'
    },
    getTop(item) {
      /**
       * 지도의 높이는 594
       */
      let top = 30;
      let calLocation = item.locationYcoordinate + (item.resultTypes.length * 76)
      if (calLocation > 594) {
        top = 594 - calLocation
      }
      return !item.isHover ? '30px' : top + 'px'
    }
  }
};
</script>
<style lang="sass">
  .topmainlayer
    height: 200px
    background-image: url('../../assets/images/mainback.png')
    display: block
    div.title
      padding: 30px 0px 0px 50px
      font-weight: 600
      color: #0070C0
      font-size: 20px
    .title1
      color: #17375E
      font-size: 25px
    .title2
      color: #17375E
      font-size: 35px
    .title3
      color: #0070C0
      font-size: 35px
    .mainbtns
      float: right !important
      margin-top: -60px
      padding-right: 20px
    .today
      color: #fb8c00
      padding-top: 35px
      padding-left: 23px
      font-weight: 600
  .mainchild
    margin-top: -75px !important
    padding: 20px 20px 5px 20px
  .maincard
    margin-right: 6px
    margin-bottom: 6px
  .maincard:hover
    margin-top: -10px
  .cardcontents
    margin-top: 6px
    margin-bottom: 6px
    margin-right: 6px
    .card-body
      padding: 10px !important
    .card-header:before
      font-family: "Material Icons"
      content: "\e876"
    .card-header
      height: 40px
      color: #1ab394
      background: #f8f8f8
      display: inline-block
      padding-top: 8px
      .card-more
        float: right !important
        cursor: pointer
        color: #d2d2d2
      .card-more:hover
        color: #1ab394
  .maincard .card-body
    background: #fff
    border-radius: 5px
    padding: 15px !important
    .fw-bold
      font-weight: 600
    .material-icons
      font-size: 3em
      color: #ddd
      cursor: pointer
  .maincard .card-body.border-color-blue
    border-left: 4px solid #0061f2
  .maincard .card-body.border-color-red
    border-left: 4px solid #d92550
  .maincard .card-body.border-color-yullow
    border-left: 4px solid #f7b924
  .maincard .card-body.border-color-green
    border-left: 4px solid #3ac47d

.mainMarkImage
  padding: 0px !important
  text-align: center
  font-size: 20px
  height: 30px !important
  position: static
  padding: 16px
  color: #C10015
  // background: rgba(0,0,0,0.47)
  .markImage-abbreviation-parent
    // width:240px
    text-align: start
    position: absolute
    .markImage-abbreviation
      cursor: pointer
      display: inline-block
      // min-width:60px
      // max-width:250px
      width: auto
      border-left: 1px solid #757575
      border-right: 1px solid #757575
      border-top: 1px solid #757575
      border-bottom: 1px solid #757575
      border-radius: 5px
      .markImage-tran
        border-radius: 5px
      .markImage-abbreviation-banner
        border-right: 1px solid #757575
        display: inline-block
        text-align: center
        min-width:60px
        padding: 1px !important
        border-radius: 5px 0px 0px 5px
        i
          font-size: 20px
        div
          font-size: 12px !important
      .markImage-abbreviation-banner.last-banner
        border-right: 0
        border-radius: 0px 5px 5px 0px
      .markImage-abbreviation-plus
        display: inline-block
        text-align: center
        min-width:40px
      .markImage-abbreviation-banner-detail
        border-radius: 5px
        .q-banner__avatar i
          font-size: 20px !important
        .q-banner__content
          font-size: 12px !important
.markImage-abbreviation > div
  -webkit-animation: fadeInFromNone 0.1s ease-out
  -moz-animation: fadeInFromNone 0.1s ease-out
  -o-animation: fadeInFromNone 0.1s ease-out
  animation: fadeInFromNone 0.1s ease-out


// .mark-list-enter-active, .mark-list-leave-active
//   transition: all 0.1s
.mark-list-enter, .mark-list-leave-to
  opacity: 0
  transform: translateY(5px)

.caption-work
  font-size: 0.85em
  font-style: italic
  white-space: normal
  color: #555
  margin-top: 4px

.workPermit-carousel
  height: 593px !important
  .q-carousel__slide
    width: 800px !important
  .q-carousel--padding, .q-carousel__slide
    padding: 0 !important
  .q-carousel__arrow
    transition: all 0.5s
    opacity: 0.2
  .q-carousel__arrow:hover
    transition: all 0.5s
    opacity: 0.8
  .q-carousel__slides-container
    .q-panel.scroll
      overflow: hidden !important

.main-header-input
  margin-top: -5px
  padding-right: 5px
  .customDate
    padding-bottom: 0 !important
    .q-field__inner
      padding: 0 !important
.anymoreNotice
  position: absolute
  bottom: 10px
  right: 20px


@-webkit-keyframes fadeInFromNone
  0%
    opacity: 0
  50%
    opacity: 0
  70%
    opacity: 0.5
  100%
    opacity: 1

@-moz-keyframes fadeInFromNone
  0%
    opacity: 0
  50%
    opacity: 0
  70%
    opacity: 0.5
  100%
    opacity: 1

@-o-keyframes fadeInFromNone
  0%
    opacity: 0
  50%
    opacity: 0
  70%
    opacity: 0.5
  100%
    opacity: 1

@keyframes fadeInFromNone
  0%
    opacity: 0
  50%
    opacity: 0
  70%
    opacity: 0.5
  100%
    opacity: 1
</style>
<style scoped>
.widget-numbers {
  font-size: 20px;
}
.impr-item {
  height: 100% !important;
}
</style>